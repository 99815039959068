import React from 'react';
import PropTypes from 'prop-types';
import { useStaticQuery, graphql } from 'gatsby';
import AtolliLink from '../utils/atolliLink';
import { pagePathOrUrl } from '../utils/pagePath';

const Hero = ({ contentModuleId }) => {
  const data = useStaticQuery(graphql`
    query {
      allContentfulSectionHero {
        edges {
          node {
            id
            heading
            subheading
            iconOrImage
            node_locale
            description {
              description
            }
            ctaText
            ctaUrl
            image {
              fluid(quality: 80, maxWidth: 600, maxHeight: 600 ) {
                ...GatsbyContentfulFluid
              }
            }
            divider
          }
        }
      }
    }
  `);

  const content = data.allContentfulSectionHero.edges.find(
    edge => edge.node.id === contentModuleId
  );

  const {
    heading,
    subheading,
    description,
    ctaText,
    ctaUrl,
    iconOrImage,
    node_locale,
    image,
    divider,
  } = content.node;

  return (
    <section className="hero">
      <div className="container">
        <div className="inner">
          <div className="hero__tagline">
            <div className="hero__tagline-content-wrap">
              <h2 className="hero__tagline-title" data-sal="fade">
                {heading}
              </h2>

              { subheading && (
                <p
                  className="hero__tagline-subtitle"
                  data-sal="fade"
                  data-sal-delay="100"
                >
                  {subheading}
                </p>
              )}

              { description && (
                <div
                  className="hero__tagline-text"
                  data-sal="fade"
                  data-sal-delay="200"
                >
                  {description.description}
                </div>
              )}

              { (ctaUrl && ctaText) && (
                <AtolliLink to={pagePathOrUrl(node_locale, ctaUrl)}>
                  <button
                    type="button"
                    className="hero__tagline-btn btn btn--secondary mt-5"
                    data-sal="fade"
                    data-sal-delay="300"
                  >
                    {ctaText}
                  </button>
                </AtolliLink>
              )}
            </div>
          </div>

          <div className={iconOrImage ? ('hero__icon') : ('hero__image')}>
            <img
              src={image.fluid.src}
              className="mx-auto"
              alt="Hero"
              data-sal="slide-right"
              data-sal-delay="400"
              data-sal-duration="500"
            />
          </div>
        </div>
      </div>
      { divider && (
        <div className="divider bg-orange"> </div>
      )}
    </section>
  );
};

Hero.propTypes = {
  contentModuleId: PropTypes.string.isRequired,
};

export default Hero;
