import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useStaticQuery, graphql } from 'gatsby';
import LogoCarouselItem from '../components/logoCarouselItem';

import { pagePathOrUrl } from '../utils/pagePath';

const LogoCarousel = ({ contentModuleId, nodeLocale }) => {
  const data = useStaticQuery(graphql`
    query {
      allContentfulSectionLogoCarousel {
        edges {
          node {
            id
            name
            divider
            logos {
              id
              name
              linkto
              image {
                fluid(quality: 80, maxWidth: 250) {
                  ...GatsbyContentfulFluid
                }
              }
            }
          }
        }
      }
    }
  `);

  const content = data.allContentfulSectionLogoCarousel.edges.find(
    edge => edge.node.id === contentModuleId
  );

  const {
    logos,
    divider,
  } = content.node;

  return (
    <section id="logoCarousel" className="logoCarousel">
      <div className="container">
        <div className="inner grid grid-cols-2 md:grid-cols-3 xl:grid-cols-7" >
          {logos.length > 0 && (
            logos.map((logoCarouselItem) => (
              <LogoCarouselItem
                key={logoCarouselItem.id}
                name={logoCarouselItem.name}
                image={logoCarouselItem.image}
                linkTo={pagePathOrUrl(nodeLocale, logoCarouselItem.linkto)}
              />
            ))
          )}
        </div>
      </div>

      { divider && (
        <div className="divider bg-blue_dark"> </div>
      )}
    </section>
  );
};

LogoCarousel.propTypes = {
  contentModuleId: PropTypes.string.isRequired,
  nodeLocale: PropTypes.string.isRequired,
};

export default LogoCarousel;
