import React from 'react';
import PropTypes from 'prop-types';
import { useStaticQuery, graphql } from 'gatsby';
import ReactMarkdownWithHtml from 'react-markdown';
import AtolliLink from '../utils/atolliLink';
import { pagePathOrUrl } from '../utils/pagePath';
import Img from 'gatsby-image';
 import PropertyAssetsImageGalleryItem from '../components/propertyAssetsImageGalleryItem';
import dynamic from 'next/dynamic'

 const LightGallery = dynamic(
  () => import('lightgallery/react'),
  { ssr: false }
)

// import styles
import 'lightgallery/css/lightgallery.css';
import 'lightgallery/css/lg-zoom.css';
import 'lightgallery/css/lg-thumbnail.css';

// import plugins if you need
import lgThumbnail from 'lightgallery/plugins/thumbnail';
import lgZoom from 'lightgallery/plugins/zoom'; 

const PlasticMaterial = ({ contentModuleId }) => {
  const data = useStaticQuery(graphql`
    query {
      allContentfulSectionPlasticMaterial {
        edges {
          node {
            id
            heading
            node_locale
            description {
              description
            }
            ctaText
            ctaUrl
            image {
              fluid(quality: 80, maxWidth: 600, maxHeight: 600 ) {
                ...GatsbyContentfulFluid
              }
            }
            propertyAssets{
              id
              title
              fluid(maxWidth: 900, quality: 80) {
                  ...GatsbyContentfulFluid
                }
            }
            divider
          }
        }
      }
    }
  `);

  const content = data.allContentfulSectionPlasticMaterial.edges.find(
    (edge) => edge.node.id === contentModuleId,
  );

  const {
    heading,
    node_locale,
    description,
    ctaText,
    ctaUrl,
    image,
    divider,
    propertyAssets,
  } = content.node;

  /* const onInit = () => {
    console.log('lightGallery has been initialized');
  };

  useEffect(() => {
    onInit();
  }); */

  return (
    <section id="plasticMaterial" className="plasticMaterial">
      <div className="container">
        <div className="inner">
          <div className="plasticMaterial__tagline">
            <div className="plasticMaterial__tagline-content-wrap">
              <h2 className="plasticMaterial__tagline-title" data-sal="fade">
                {heading}
              </h2>

              { description && (
              <div
                className="plasticMaterial__tagline-text"
                data-sal="fade"
                data-sal-delay="200"
              >
               
                <ReactMarkdownWithHtml children={description.description} allowDangerousHtml />
              </div>
              )}

              { (ctaUrl && ctaText) && (
              <AtolliLink to={pagePathOrUrl(node_locale, ctaUrl)}>
                <button
                  type="button"
                  className="plasticMaterial__tagline-btn btn btn--secondary mt-5"
                  data-sal="fade"
                  data-sal-delay="300"
                >
                  {ctaText}
                </button>
              </AtolliLink>
              )}
            </div>
          </div>
          <div className="plasticMaterial__image">
            <img
              src={image.fluid.src}
              className="mx-auto"
              alt="Plastic Material"
              data-sal="slide-right"
              data-sal-delay="400"
              data-sal-duration="500"
            />
          </div>
        </div>
        <div className="plasticMaterial-propertyAssets-image-table" data-sal="fade" data-sal-duration="1000">
            <LightGallery
           /* onInit={onInit} */
            speed={500}
            plugins={[lgThumbnail, lgZoom]}
              > 
          {propertyAssets.length > 0 && (
            propertyAssets.map((propertyAssetsItem) => (
              <PropertyAssetsImageGalleryItem mode="lg-fade" imageGalleryItem={propertyAssetsItem} key={propertyAssetsItem.id} />
            ))
          )}
           </LightGallery> 
        </div>
      </div>
      { divider && (
      <div className="divider bg-orange"> </div>
      )}
    </section>
  );
};

PlasticMaterial.propTypes = {
  contentModuleId: PropTypes.string.isRequired,
};

export default PlasticMaterial;
