import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useStaticQuery, graphql } from 'gatsby';
import sal from 'sal.js';

import Header from './header';
import Footer from './footer';

const Layout = ({ children, menus, nodeLocale, slug }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `);

  // Initialize scroll animations
  useEffect(() => {
    sal({
      threshold: 0.1,
      once: true,
    });
  }, []);

  return (
    <div className="wrapper overflow-hidden">
      <h1 className="hidden">{data.site.siteMetadata.title}</h1>
      <Header menus={menus} nodeLocale={nodeLocale} slug={slug} />
      <div className="main-container">{children}</div>
      <Footer menus={menus} nodeLocale={nodeLocale} />
    </div>
  );
};

Layout.defaultProps = {
  menus: null,
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  menus: PropTypes.any,
  nodeLocale: PropTypes.string.isRequired,
  slug: PropTypes.string.isRequired,
};

export default Layout;
