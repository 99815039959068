import React from 'react';
import PropTypes from 'prop-types';
import Hero from 'sections/hero';
import PlasticMaterial from 'sections/plasticMaterial';
import GeneralSinglePlasticMaterial from 'sections/generalSinglePlasticMaterial';
import AllPlasticMaterialsMenu from 'sections/plasticMaterialsMenu';
import Breadcrumb from 'sections/breadcrumb';
import HeroCarousel from 'sections/heroCarousel';
import About from 'sections/about';
import News from 'sections/news';
import Testimonials from 'sections/testimonials';
import References from 'sections/references';
import Services from 'sections/services';
import ServiceDescription from 'sections/serviceDescription';
import Pricing from 'sections/pricing';
import Products from 'sections/products';
import Contact from 'sections/contact';
import LogoCarousel from 'sections/logoCarousel';
import SingleVideo from 'sections/singleVideo';
import ImageGallery from 'sections/imageGallery';
import ImageGalleryCol4 from 'sections/imageGalleryCol4';
import PageNotFound from 'sections/pageNotFound';

const sectionComponents = {
  Hero,
  GeneralSinglePlasticMaterial,
  PlasticMaterial,
  AllPlasticMaterialsMenu,
  Breadcrumb,
  HeroCarousel,
  About,
  News,
  Testimonials,
  References,
  Services,
  Pricing,
  Products,
  Contact,
  ServiceDescription,
  LogoCarousel,
  SingleVideo,
  ImageGallery,
  ImageGalleryCol4,
};

const layoutComponents = {
  PageNotFound,
};

// Determine React component name from Contentful API name:
// "ContentfulSectionAbout" -> "About"
const getComponentName = (type, str) => {
  console.log("DEBUG getComponentName type, str:", type, str);

  return type.split(str)[1];
};

const Section = ({ contentModuleId, type, nodeLocale }) => {
  console.log('DEBUG Section contentModuleId, type, nodeLocale: ', contentModuleId, type, nodeLocale);

  const sectionComponent = getComponentName(type, 'Section');
  const layoutComponent = getComponentName(type, 'Layout');
  let reactComponent;

  if (typeof sectionComponents[sectionComponent] !== 'undefined') {
    reactComponent = sectionComponents[sectionComponent];
  } else if (typeof layoutComponents[layoutComponent] !== 'undefined') {
    reactComponent = layoutComponents[layoutComponent];
  } else {
    throw new Error(`Unknown section or layout: ${type}`);
  }

  return React.createElement(reactComponent, {
    contentModuleId,
    nodeLocale,
  });
};

Section.prototype = {
  contentModuleId: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  nodeLocale: PropTypes.string.isRequired,
};

export default Section;
