import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useStaticQuery, graphql } from 'gatsby';

import Glider from 'glider-js';
import Testimonial from '../components/testimonial';

const Testimonials = ({ contentModuleId }) => {
  const data = useStaticQuery(graphql`
    query {
      allContentfulSectionTestimonials {
        edges {
          node {
            id
            heading
            description {
              description
            }
            testimonials {
              id
              name
              company
              comment {
                comment
              }
              image {
                fluid(maxWidth: 600 quality: 80) {
                  ...GatsbyContentfulFluid
                }
              }
            }
          }
        }
      }
    }
  `);

  const content = data.allContentfulSectionTestimonials.edges.find(
    (edge) => edge.node.id === contentModuleId
  );

  const initSlider = () => {
    new Glider(document.querySelector('.glider-testimonials'), {
      slidesToShow: 1,
      dots: '.glider-testimonials__dots',
      draggable: true,
      arrows: {
        prev: '.glider-testimonials-prev',
        next: '.glider-testimonials-next',
      },
    });
  };

  useEffect(() => {
    initSlider();
  });

  return (
    <section
      id="testimonials"
      className="testimonials container section mx-auto"
    >
      <div className="w-full md:w-1/2 pl-0 md:pl-16 text-center md:text-left">
        <h2
          className="w-full md:w-3/4 font-bold text-5xl leading-none mb-6"
          data-sal="fade"
          data-sal-easing="ease-in-cubic"
        >
          {content.node.heading}
        </h2>
        <p
          className="w-full md:w-3/4"
          data-sal="fade"
          data-sal-easing="ease-in-cubic"
        >
          {content.node.description.description}
        </p>
      </div>
      <div className="w-full md:w-1/2 pt-12 md:pt-0">
        {content.node.testimonials.length > 0 && (
          <div
            className="testimonial__slider"
            data-sal="fade"
            data-sal-easing="ease-in-cubic"
          >
            <div className="glider glider-testimonials">
              {content.node.testimonials.map(testimonial => (
                <Testimonial testimonial={testimonial} key={testimonial.id} />
              ))}
            </div>
            <button type="button" className="glider-prev glider-testimonials-prev material-icons">
              keyboard_arrow_left
            </button>
            <button type="button" className="glider-next glider-testimonials-next material-icons">
              keyboard_arrow_right
            </button>
            <div role="tablist" className="glider-testimonials__dots" />
          </div>
        )}
      </div>
    </section>
  );
};

Testimonials.propTypes = {
  contentModuleId: PropTypes.string.isRequired,
};

export default Testimonials;
