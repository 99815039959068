// langFromLocale() returns language from a given locale.
const langFromLocale = (key) => {
  const t = {
    'fi-FI': 'fi',
    'en-US': 'en',
  };

  return t[key];
};

// pagePath() constructs a page path from given slug by prefixing it with lang
export const pagePath = (locale, slug) => `/${langFromLocale(locale)}${slug}`;

export const pagePathOrUrl = (locale, slugOrUrl) => {
  return slugOrUrl.startsWith('/') ? pagePath(locale, slugOrUrl) : slugOrUrl;
};

export default { pagePath, pagePathOrUrl };
