import React from 'react';
import PropTypes from 'prop-types';
import AtolliLink from '../utils/atolliLink';
import Img from 'gatsby-image';

const Product = ({ product }) => (
  <div
    className={`product__item ${product.featured ? 'product__item--active' : ''} `}
    data-sal="fade"
    data-sal-easing="ease-in-cubic"
    data-sal-duration="400"
  >
    <div className="product__item-content">
      <Img
        fluid={product.image.fluid}
        alt={product.name}
        className="product__item-image"
      />
      <h3 className="product__item-name">{product.name}</h3>
      <p className="product__item-description">
        <span>{product.description}</span>
      </p>
    </div>
    <AtolliLink
      to={product.linkUrl}
      className={`btn ${!product.featured ? 'btn--primary' : ''}`}
    >
      {product.linkText}
    </AtolliLink>
  </div>
);

Product.propTypes = {
  product: PropTypes.object.isRequired,
};

export default Product;
