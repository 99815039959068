import React from 'react';
import PropTypes from 'prop-types';
import { useStaticQuery, graphql } from 'gatsby';
import PlasticMaterialDropdownItem from '../components/plasticMaterialDropdownItem';

const AllPlasticMaterialsMenu = ({ contentModuleId }) => {
  const data = useStaticQuery(graphql`
    query {
      allContentfulSectionAllPlasticMaterialsMenu {
        edges {
          node {
            id
            name
            node_locale
            dropdown {
              id
              name
              menuItems {
                id
                title
                url
              }
            }
            divider
          }
        }
      }
    }
  `);

  const content = data.allContentfulSectionAllPlasticMaterialsMenu.edges.find(
    (edge) => edge.node.id === contentModuleId,
  );

  const {
    node_locale,
    id,
    dropdown,
    divider,
  } = content.node;

  return (
    <section id="plasticMaterialsMenu" className="plasticMaterialsMenu">
      <div className="container">
        <div className="inner">

          {dropdown !== null && dropdown !== undefined
            && (

              dropdown.map((menuItems) => (

                <div className="plasticMaterialsMenu_content" key={menuItems.name + id}>

                  <div
                    className="mx-auto"
                    data-sal="slide-right"
                    data-sal-delay="400"
                    data-sal-duration="500"
                  >
                    <PlasticMaterialDropdownItem
                      plasticMaterialItem={menuItems}
                      nodeLocale={node_locale}
                      key={menuItems.id}
                    />
                  </div>
                </div>
              ))

            )}
        </div>
      </div>
      { divider && (
        <div className="divider bg-white"> </div>
      )}
    </section>
  );
};

AllPlasticMaterialsMenu.propTypes = {
  contentModuleId: PropTypes.string.isRequired,
};

export default AllPlasticMaterialsMenu;
