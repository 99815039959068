import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useStaticQuery, graphql } from 'gatsby';

import Glider from 'glider-js';
import HeroCarouselItem from '../components/heroCarouselItem';

import { pagePath } from '../utils/pagePath';

const HeroCarousel = ({ contentModuleId, nodeLocale }) => {
  const data = useStaticQuery(graphql`
    query {
      allContentfulSectionHeroCarousel {
        edges {
          node {
            id
            name
            divider
            itemsDisplayedAtOnce
            itemsToScrollAtOnce
            heroCarouselItems {
              id
              name
              linkto {
                slug
              }
              image {
                fluid(maxWidth: 400, quality: 80) {
                  ...GatsbyContentfulFluid
                }
              }
            }
          }
        }
      }
    }
  `);

  const content = data.allContentfulSectionHeroCarousel.edges.find(
    (edge) => edge.node.id === contentModuleId
  );

  const {
    heroCarouselItems,
    itemsDisplayedAtOnce,
    itemsToScrollAtOnce,
    divider,
  } = content.node;

  // https://nickpiscitelli.github.io/Glider.js/
  const initSlider = () => {
    const glider = new Glider(document.querySelector('.glider-heroCarousel'), {
      slidesToShow: 1,
      slidesToScroll: 1,
      itemWidth: 150,
      dots: '.glider-heroCarousel__dots',
      draggable: true,
      arrows: {
        prev: '.glider-heroCarousel-prev',
        next: '.glider-heroCarousel-next',
      },
      responsive: [
        {
          // screens greater than
          breakpoint: 768,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1,
            itemWidth: 150,
            duration: 0.25
          }
        },{
          // screens greater than
          breakpoint: 1180,
          settings: {
            slidesToShow: itemsDisplayedAtOnce,
            slidesToScroll: itemsToScrollAtOnce,
            itemWidth: 150,
            duration: 0.25
          }
        }
      ]
    });
  };

  useEffect(() => {
    initSlider();
  });

  return (
    <section id="heroCarousel" className="heroCarousel">
      <div className="container">
        <div className="inner">
          {heroCarouselItems.length > 0 && (
            <div className="heroCarousel__slider">
              <div className="glider-contain">
                <div className="glider glider-heroCarousel">
                  {heroCarouselItems.map((heroCarouselItem) => (
                    <HeroCarouselItem
                      key={heroCarouselItem.id}
                      name={heroCarouselItem.name}
                      image={heroCarouselItem.image}
                      linkTo={pagePath(nodeLocale, heroCarouselItem.linkto.slug)}
                    />
                  ))}
                </div>
                <button type="button" className="glider-prev glider-heroCarousel-prev material-icons">
                  keyboard_arrow_left
                </button>
                <button type="button" className="glider-next glider-heroCarousel-next material-icons">
                  keyboard_arrow_right
                </button>
                <div role="tablist" className="glider-heroCarousel__dots" />
              </div>
            </div>
          )}
        </div>
      </div>

      { divider && (
        <div className="divider bg-blue_light"> </div>
      )}
    </section>
  );
};

HeroCarousel.propTypes = {
  contentModuleId: PropTypes.string.isRequired,
  nodeLocale: PropTypes.string.isRequired,
};

export default HeroCarousel;
