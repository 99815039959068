import React from 'react';
import PropTypes from 'prop-types';
import Img from 'gatsby-image';
import ReactMarkdownWithHtml from 'react-markdown';
import { pagePathOrUrl } from '../utils/pagePath';
import AtolliLink from '../utils/atolliLink';

const ServiceDescriptionItem = ({ heading, content, image, ctaText, ctaUrl, nodeLocale }) => (
  <div className="serviceDescriptionItem">
    <div className="serviceDescription__content">
      <div className="serviceDescription__content-wrap">
        <h2
          className="section__title serviceDescription__heading"
          data-sal="fade"
          data-sal-easing="ease-in-cubic"
        >
          {heading}
        </h2>

        { content && (
          <div
            className="serviceDescription__richtext"
            data-sal="fade"
          >
            <ReactMarkdownWithHtml children={content} allowDangerousHtml />
          </div>
        )}

        { (ctaText && ctaUrl) && (
          <AtolliLink to={pagePathOrUrl(nodeLocale, ctaUrl)}>
            <button
              type="button"
              className="btn btn--secondary"
              data-sal="fade"
              data-sal-delay="300"
            >
              {ctaText}
            </button>
          </AtolliLink>
        )}
      </div>

      { image && (
        <div
          className="serviceDescription__image-wrap"
          data-sal="slide-up"
          data-sal-duration="500"
        >
          <Img
            fluid={image.fluid}
            alt={heading}
            className="serviceDescription__image"
          />
        </div>
      )}

    </div>
  </div>
);

ServiceDescriptionItem.propTypes = {
  heading: PropTypes.string.isRequired,
  content: PropTypes.string.isRequired,
  image: PropTypes.object,
  ctaText: PropTypes.string,
  ctaUrl: PropTypes.string,
  nodeLocale: PropTypes.string.isRequired,
};

export default ServiceDescriptionItem;
