import React from 'react';
import PropTypes from 'prop-types';
import { useStaticQuery, graphql } from 'gatsby';
import AtolliLink from '../utils/atolliLink';
import { pagePath, pagePathOrUrl } from '../utils/pagePath';

const Breadcrumb = ({ contentModuleId }) => {
  const data = useStaticQuery(graphql`
    query {
      allContentfulSectionBreadcrumb {
        edges {
          node {
            id
            name
            node_locale
            menuItems {
                id
                title
                url
            }
          }
        }
      }
    }
  `);

  const content = data.allContentfulSectionBreadcrumb.edges.find(
    (edge) => edge.node.id === contentModuleId,
  );

  const {
    node_locale,
    id,
    menuItems,
  } = content.node;

  return (
    <section id="breadcrumb" className="breadcrumb">
      <div className="container">
        <div className="inner">

          {menuItems !== null && menuItems !== undefined
            && (

              menuItems.map((item) => (
                <div
                  key={item.id}
                  className="breadcrumb_item"
                  data-sal="slide-right"
                  data-sal-delay="400"
                  data-sal-duration="500"
                >
                  <AtolliLink to={pagePathOrUrl(node_locale, item.url)}>
                    {item.title}
                  </AtolliLink>
                </div>

              ))

            )}
        </div>
      </div>
    </section>
  );
};

Breadcrumb.propTypes = {
  contentModuleId: PropTypes.string.isRequired,
};

export default Breadcrumb;
