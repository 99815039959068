import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import PropTypes from 'prop-types';
import ServiceDescriptionItem from '../components/serviceDescriptionItem';

const ServiceDescription = ({ contentModuleId }) => {
  const data = useStaticQuery(graphql`
  query ContentfulQuery {
    allContentfulSectionServiceDescription {
      edges {
        node {
          id
          node_locale
          heading
          ctaText
          ctaUrl
          image {
            fluid(maxWidth: 720, maxHeight: 720, quality: 80) {
              ...GatsbyContentfulFluid
            }
          }
          description2{
            id
            description2
          }
        }
      }
    }
  }
  `);

  const content = data.allContentfulSectionServiceDescription.edges.find(
    (edge) => edge.node.id === contentModuleId
  );

  const {
    heading,
    description2,
    image,
    ctaText,
    ctaUrl,
    node_locale
  } = content.node;

  return (
    <section className="serviceDescription">
      <div className="container">
        <div className="inner">
          <ServiceDescriptionItem
            heading={heading}
            content={description2.description2}
            image={image}
            ctaText={ctaText}
            ctaUrl={ctaUrl}
            nodeLocale={node_locale}
          />
        </div>
      </div>
    </section>

  );
};

export default ServiceDescription;
