import React from 'react';
import PropTypes from 'prop-types';
import Img from 'gatsby-image';

import AtolliLink from '../utils/atolliLink';

const PropertyAssetsImageGalleryItem = ({ imageGalleryItem }) => (

  <AtolliLink className="propertyImageGallery__item-link" to={imageGalleryItem.fluid.src} rel="nofollow">
    <Img
      fluid={imageGalleryItem.fluid}
      className="propertyImageGallery__item-image"
      data-src={imageGalleryItem.fluid.src}
    />
    <div className="propertyImageGallery__item-name">{imageGalleryItem.title}</div>
  </AtolliLink>

);

PropertyAssetsImageGalleryItem.propTypes = {
  imageGalleryItem: PropTypes.object.isRequired,
};

export default PropertyAssetsImageGalleryItem;
