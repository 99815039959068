import React from 'react';
import PropTypes from 'prop-types';
import Img from 'gatsby-image';
import AtolliLink from '../utils/atolliLink';

const LogoCarouselItem = ({ linkTo, image, name }) => (
  <div
  data-sal-duration="1000"
  data-sal="fade"
  >
    <AtolliLink to={linkTo} target="_blank" rel="nofollow">
      <Img
        fluid={image.fluid}
        alt={name}
        className="logoCarouselItem__image"
      />
    </AtolliLink>
  </div>
);

LogoCarouselItem.propTypes = {
  linkTo: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  image: PropTypes.object.isRequired,
};

export default LogoCarouselItem;
