import React from 'react';
import PropTypes from 'prop-types';
import ReactMarkdownWithHtml from 'react-markdown';
import { useStaticQuery, graphql } from 'gatsby';
import AtolliLink from '../utils/atolliLink';
import { pagePathOrUrl } from '../utils/pagePath';

const GeneralSinglePlasticMaterial = ({ contentModuleId }) => {
  const data = useStaticQuery(graphql`
    query {
      allContentfulSectionGeneralSinglePlasticMaterial {
        edges {
          node {
            id
            heading
            node_locale
            description {
              description
            }
            ctaText
            ctaUrl
            image {
              fluid(quality: 80, maxWidth: 600, maxHeight: 600 ) {
                ...GatsbyContentfulFluid
              }
            }
          }
        }
      }
    }
  `);

  const content = data.allContentfulSectionGeneralSinglePlasticMaterial.edges.find(
    (edge) => edge.node.id === contentModuleId,
  );

  const {
    heading,
    node_locale,
    description,
    ctaText,
    ctaUrl,
    image,
  } = content.node;

  return (
    <section id="generalSinglePlasticMaterial" className="generalSinglePlasticMaterial">
      <div className="container">
        <div className="inner">
          <div className="generalSinglePlasticMaterial__tagline">
            <div className="generalSinglePlasticMaterial__tagline-content-wrap">
              <h2 className="generalSinglePlasticMaterial__tagline-title" data-sal="fade">
                {heading}
              </h2>

              { description && (
              <div
                className="generalSinglePlasticMaterial__tagline-text"
                data-sal="fade"
                data-sal-delay="200"
              >

                <ReactMarkdownWithHtml children={description.description} allowDangerousHtml />

              </div>
              )}

              { (ctaUrl && ctaText) && (
              <AtolliLink to={pagePathOrUrl(node_locale, ctaUrl)}>
                <button
                  type="button"
                  className="generalSinglePlasticMaterial__tagline-btn btn btn--secondary mt-5"
                  data-sal="fade"
                  data-sal-delay="300"
                >
                  {ctaText}
                </button>
              </AtolliLink>
              )}
            </div>
          </div>
          <div className="generalSinglePlasticMaterial__image">
            <img
              src={image.fluid.src}
              className="mx-auto"
              alt="generalSinglePlasticMaterial"
              data-sal="slide-right"
              data-sal-delay="400"
              data-sal-duration="500"
            />
          </div>
        </div>
      </div>
    </section>
  );
};

GeneralSinglePlasticMaterial.propTypes = {
  contentModuleId: PropTypes.string.isRequired,
};

export default GeneralSinglePlasticMaterial;
