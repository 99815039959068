import React from 'react';
import PropTypes from 'prop-types';
import { useStaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';
import AboutItem from 'components/aboutItem';
import { pagePathOrUrl } from '../utils/pagePath';
import AtolliLink from '../utils/atolliLink';

const About = ({ contentModuleId }) => {
  const data = useStaticQuery(graphql`
    query {
      allContentfulSectionAbout {
        edges {
          node {
            id
            heading
            ctaText
            ctaUrl
            divider
            node_locale
            description {
              description
            }
            featureItem {
              id
              title
              icon
              description {
                description
              }
            }
            image {
              fluid(maxWidth: 600, quality: 80) {
                ...GatsbyContentfulFluid
              }
            }
          }
        }
      }
    }
  `);

  const content = data.allContentfulSectionAbout.edges.find(
    edge => edge.node.id === contentModuleId
  );

  const {
    heading,
    description,
    image,
    featureItem,
    ctaText,
    ctaUrl,
    divider,
    node_locale,
  } = content.node;

  return (
    <section id="about" className="about-us">
      <div className="container">
        <div className="inner">
          <div className="about-us__content">
            <h2
              className="section__title"
              data-sal="fade"
              data-sal-easing="ease-in-cubic"
            >
              {heading}
            </h2>
            <p
              data-sal="slide-up"
              data-sal-easing="ease-in-cubic"
              data-sal-delay="100"
            >
              {description.description}
            </p>
            {featureItem.length > 0 && (
              <ul>
                {featureItem.map((feature) => (
                  <AboutItem feature={feature} key={feature.id} />
                ))}
              </ul>
            )}
            { (ctaText && ctaUrl) && (
              <AtolliLink to={pagePathOrUrl(node_locale, ctaUrl)}>
                <button
                  type="button"
                  className="btn btn--primary"
                  data-sal="fade"
                  data-sal-delay="300"
                >
                  {ctaText}
                </button>
              </AtolliLink>
            )}
          </div>
          <div className="about-us__image">
            <div
              className="about-us__image-wrap"
              data-sal="slide-up"
              data-sal-delay="200"
              data-sal-duration="500"
            >
              <Img fluid={image.fluid} />
            </div>
          </div>
        </div>
      </div>

      { divider && (
        <div className="divider bg-white"> </div>
      )}
    </section>
  );
};

About.propTypes = {
  contentModuleId: PropTypes.string.isRequired,
};

export default About;
