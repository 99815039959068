import React from 'react';
import PropTypes from 'prop-types';
import { useStaticQuery, graphql } from 'gatsby';

const Footer = ({ menus, nodeLocale }) => {
  const data = useStaticQuery(graphql`
    query {
      allContentfulItemContactDetails {
        edges {
          node {
            id
            node_locale
            companyName
            companyVatId
            addressTitle
            addressLine1
            addressLine2
            addressLine1_2
            addressLine2_2
            contactNumber
            email
            officeHoursTitle
            officeHours1
            officeHours2
            addressLine1Link
            addressLine2Link
          }
        }
      }
    }
  `);

  const footerMenu =
    menus !== null && menus !== undefined
      ? menus.find((menu) => menu.type === 'secondary')
      : null;

  const content = data.allContentfulItemContactDetails.edges.find(
    (edge) => edge.node.node_locale === nodeLocale
  );

  const {
    companyName,
    companyVatId,
    addressTitle,
    addressLine1,
    addressLine2,
    addressLine1_2,
    addressLine2_2,
    contactNumber,
    email,
    officeHoursTitle,
    officeHours1,
    officeHours2,
    addressLine1Link,
    addressLine2Link,
  } = content.node;

  return (
    <footer className="footer">
      <div className="container">
        <div className="inner">
          <div className="footer__content">
            <h3
              data-sal="fade" data-sal-duration="1000"
            >
              {addressTitle}
            </h3>
            <ul>
              <li data-sal="fade" data-sal-duration="1000">
                <a href={addressLine1Link} target="_blank">
                  <div className="item">
                    <i className="item__icon material-icons-outlined">
                      location_on
                    </i>
                    <div className="item__content">
                      <p className="item__text">
                        {addressLine1}
                        <br />
                        {addressLine2}
                      </p>
                    </div>
                  </div>
                </a>
              </li>
              <li data-sal="fade" data-sal-duration="1000">
                <a href={addressLine2Link} target="_blank">
                  <div className="item">
                    <i className="item__icon material-icons-outlined">
                    local_shipping
                    </i>
                    <div className="item__content">
                      <p className="item__text">
                        {addressLine1_2}
                        <br />
                        {addressLine2_2}
                      </p>
                    </div>
                  </div>
                </a>
              </li >
              <li data-sal="fade" data-sal-duration="1000">
                <div className="item">
                  <i className="item__icon material-icons-outlined">
                    info
                  </i>
                  <div className="item__content">
                    <p className="item__text">
                      {companyName}
                      <br />
                      {companyVatId}
                      <br />
                    </p>
                  </div>
                </div>
              </li>

            </ul>
          </div>

          <div className="footer__content">
            <h3 data-sal="fade" data-sal-duration="1000">
              {officeHoursTitle}
            </h3>
            <ul>
              <li data-sal="fade" data-sal-duration="1000">
                <div className="item">
                  <i className="item__icon material-icons-outlined">
                    av_timer
                  </i>
                  <div className="item__content">
                    <p className="item__text">
                      {officeHours1}
                      <br />
                      {officeHours2}
                    </p>
                  </div>
                </div>
              </li>
              <li data-sal="fade" data-sal-duration="1000">
                <a href={"tel:" + contactNumber} target="_blank">
                  <div className="item">
                    <i className="item__icon material-icons-outlined">
                      phone
                    </i>
                    <div className="item__content">
                      <p className="item__text">
                        {contactNumber}
                      </p>
                    </div>
                  </div>
                </a>
              </li>
              <li data-sal="fade" data-sal-duration="1000">
                <a href={"mailto:" + email} target="_blank">
                  <div className="item">
                    <i className="item__icon material-icons-outlined ">
                      email
                    </i>
                    <div className="item__content">
                      <p className="item__text">
                        {email}
                      </p>
                    </div>
                  </div>
                </a>
              </li>
            </ul>
          </div>
        </div>
        <div className="footer__menu" >
          <ul data-sal="flip-right" data-sal-duration="1500">
            {footerMenu && footerMenu.menuItems.map((menu) => (
              <li key={menu.id}>
                <a href={menu.url}>
                  {menu.title}
                </a>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </footer>
  );
};

Footer.defaultProps = {
  menus: null,
};

Footer.propTypes = {
  menus: PropTypes.any,
  nodeLocale: PropTypes.string.isRequired,
};

export default Footer;
