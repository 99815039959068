import React from 'react';
import PropTypes from 'prop-types';
import { useStaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';
import { pagePathOrUrl } from '../utils/pagePath';
import ReactMarkdownWithHtml from 'react-markdown';
import AtolliLink from '../utils/atolliLink';

const News = ({ contentModuleId }) => {
  const data = useStaticQuery(graphql`
    query {
      allContentfulSectionNews {
        edges {
          node {
            id
            title
            ctaText
            ctaUrl
            node_locale
            divider
            description {
              description
            }
            image {
              fluid(maxWidth: 600, quality: 80) {
                ...GatsbyContentfulFluid
              }
            }
          }
        }
      }
    }
  `);

  const content = data.allContentfulSectionNews.edges.find(
    edge => edge.node.id === contentModuleId
  );

  const {
    title,
    description,
    image,
    ctaText,
    ctaUrl,
    divider,
    node_locale,
  } = content.node;

  return (
    <section id="news" className="news" >
      <div className="container">
        <div className="inner">
          <div className="news__content">
            <h2
              className="section__title"
              data-sal="fade"
              data-sal-easing="ease-in-cubic"
            >
              {title}
            </h2>

            <div>
              { description.description && (
                <div className="news__richtext">
                  <ReactMarkdownWithHtml children={description.description} />
                </div>
              )}
            </div>

            { (ctaText && ctaUrl) && (
              <AtolliLink to={pagePathOrUrl(node_locale, ctaUrl)}>
                <button
                  type="button"
                  className="btn btn--primary"
                  data-sal="fade"
                  data-sal-delay="300"
                >
                  {ctaText}
                </button>
              </AtolliLink>
            )}
          </div>
          <div className="news__image">
            <div
              className="news__image-wrap"
              data-sal="slide-up"
              data-sal-delay="200"
              data-sal-duration="500"
            >
              <Img fluid={image.fluid} />
            </div>
          </div>
        </div>
      </div>

      { divider && (
        <div className="divider bg-white"> </div>
      )}
    </section>
  );
};

News.propTypes = {
  contentModuleId: PropTypes.string.isRequired,
};

export default News;
