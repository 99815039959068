import React from 'react';
import PropTypes from 'prop-types';
import { useStaticQuery, graphql } from 'gatsby';

import Service from '../components/service';

import { pagePath } from '../utils/pagePath';

const Services = ({ contentModuleId, nodeLocale }) => {
  const data = useStaticQuery(graphql`
    query {
      allContentfulSectionServices {
        edges {
          node {
            id
            heading
            divider
            serviceItems {
              id
              title
              linksTo {
                slug
              }
              image {
                fluid(quality: 80, maxWidth: 300, maxHeight: 300 ) {
                  ...GatsbyContentfulFluid
                }
              }
            }
          }
        }
      }
    }
  `);

  const content = data.allContentfulSectionServices.edges.find(
    (edge) => edge.node.id === contentModuleId
  );

  return (
    <section id="services" className="services">
      <div className="container">
        <div className="inner">
          <h2
            className="section__title"
            data-sal="fade"
            data-sal-easing="ease-in-cubic"
          >
            {content.node.heading}
          </h2>
          {content.node.serviceItems.length > 0 && (
            <div className="services__items">
              {content.node.serviceItems.map((service) => (
                <Service
                  key={service.id}
                  linkTo={pagePath(nodeLocale, service.linksTo.slug)}
                  title={service.title}
                  image={service.image}
                />
              ))}
            </div>
          )}
        </div>
      </div>

      { content.node.divider && (
        <div className="divider bg-blue_dark"> </div>
      )}
    </section>
  );
};

Services.propTypes = {
  contentModuleId: PropTypes.string.isRequired,
  nodeLocale: PropTypes.string.isRequired,
};

export default Services;
