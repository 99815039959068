import React from 'react';
import PropTypes from 'prop-types';
import Img from 'gatsby-image';
import AtolliLink from '../utils/atolliLink';

const Service = ({ linkTo, title, image }) => (
  <AtolliLink to={linkTo}>
    <div
      className="service"
      data-sal="fade"
      data-sal-easing="ease-in-cubic"
      data-sal-duration="400"
    >
      <div className="service__image-wrap">
        <Img
          fluid={image.fluid}
          alt="service item"
          className="service__image"
        />
      </div>
      <h3 className="service__title">{title}</h3>
    </div>
  </AtolliLink>
);

Service.propTypes = {
  title: PropTypes.string.isRequired,
  image: PropTypes.object.isRequired,
};

export default Service;
