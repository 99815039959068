import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useStaticQuery, graphql } from 'gatsby';
import dynamic from 'next/dynamic'

import ImageGalleryItem from '../components/imageGalleryItem';

const LightGallery = dynamic(
  () => import('lightgallery/react'),
  { ssr: false }
)

// import styles
import 'lightgallery/css/lightgallery.css';
import 'lightgallery/css/lg-zoom.css';
import 'lightgallery/css/lg-thumbnail.css';

// import plugins if you need
import lgThumbnail from 'lightgallery/plugins/thumbnail';
import lgZoom from 'lightgallery/plugins/zoom';

const ImageGalleryCol4 = ({ contentModuleId }) => {
  const data = useStaticQuery(graphql`
    query {
      allContentfulSectionImageGalleryCol4 {
        edges {
          node {
            id
            name
            divider
            images {
              id
              name
              image {
                fluid(maxWidth: 900, quality: 80) {
                  ...GatsbyContentfulFluid
                }
              }
            }
          }
        }
      }
    }
  `);

  const content = data.allContentfulSectionImageGalleryCol4.edges.find(
    (edge) => edge.node.id === contentModuleId,
  );

  const {
    images,
    divider,
  } = content.node;

  /* const onInit = () => {
     console.log('lightGallery has been initialized'); 
  };

  useEffect(() => {
    onInit();
  }); */

  return (
    <section id="imageGalleryCol4" className="imageGalleryCol4">
      <div className="container">
        <div className="inner" data-sal="fade" data-sal-duration="1000">
          <LightGallery
           /* onInit={onInit} */
            speed={500}
            plugins={[lgThumbnail, lgZoom]}
          >
          
            {images.length > 0 && (

              images.map((imageGalleryItem) => (
             
                <ImageGalleryItem mode="lg-fade" imageGalleryItem={imageGalleryItem} key={imageGalleryItem.id} />

              ))
            )}
          </LightGallery>

        </div>
      </div>

      { divider && (
        <div className="divider bg-blue_dark"> </div>
      )}
    </section>
  );
};

ImageGalleryCol4.propTypes = {
  contentModuleId: PropTypes.string.isRequired,
};

export default ImageGalleryCol4;
