import React from 'react';
import PropTypes from 'prop-types';
import Img from 'gatsby-image';
import AtolliLink from '../utils/atolliLink';

const Reference = ({ reference }) => (
  <div className="reference__item">
    <div
      className="reference__item-content"
      data-sal="fade"
      data-sal-easing="ease-in-cubic"
      data-sal-duration="500"

    >
      <Img
        fluid={reference.image.fluid}
        alt={reference.name}
        className="reference__item-image"
      />

      <strong className="reference__item-name">{reference.name}</strong>

      <p className="reference__item-heading">
        {reference.heading}
      </p>

      <p className="reference__item-description">
        {reference.description}
      </p>

      { (reference.linkUrl && reference.linkText) && (
        <AtolliLink
          to={reference.linkUrl}
          className="btn btn--primary"
        >
          {reference.linkText}
        </AtolliLink>
      )}

    </div>
  </div>
);

Reference.propTypes = {
  reference: PropTypes.object.isRequired,
};

export default Reference;
