import React, { useEffect }  from 'react';
import PropTypes from 'prop-types';
import { useStaticQuery, graphql } from 'gatsby';

import Glider from 'glider-js';
import Product from '../components/product';

const Products = ({ contentModuleId }) => {
  const data = useStaticQuery(graphql`
    query {
      allContentfulSectionProducts {
        edges {
          node {
            id
            heading
            node_locale
            itemsDisplayedAtOnce
            itemsToScrollAtOnce
            productItems {
              id
              name
              description
              featured
              linkText
              linkUrl
              image {
                fluid(maxWidth: 400, quality: 80) {
                  ...GatsbyContentfulFluid
                }
              }
            }
          }
        }
      }
    }
  `);

  const content = data.allContentfulSectionProducts.edges.find(
    (edge) => edge.node.id === contentModuleId
  );

  const {
    productItems,
    itemsDisplayedAtOnce,
    itemsToScrollAtOnce
  } = content.node;

  // https://nickpiscitelli.github.io/Glider.js/
  const initProductSlider = () => {
    const glider = new Glider(document.querySelector('.glider-productCarousel'), {
      slidesToShow: 1,
      slidesToScroll: 1,
      itemWidth: 150,
      dots: '.glider-productCarousel__dots',
      draggable: true,
      arrows: {
        prev: '.glider-productCarousel-prev',
        next: '.glider-productCarousel-next',
      },
      responsive: [
        {
          // screens greater than
          breakpoint: 768,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1,
            itemWidth: 150,
            duration: 0.25
          }
        },{
          // screens greater than
          breakpoint: 1180,
          settings: {
            slidesToShow: itemsDisplayedAtOnce,
            slidesToScroll: itemsToScrollAtOnce,
            itemWidth: 150,
            duration: 0.25,
          },
        },
      ],
    });
  };

  useEffect(() => {
    initProductSlider();
  });

  return (
    <section id="products" className="products">
      <div className="container">
        <div className="inner">
          <h2
            className="section__title"
            data-sal="fade"
            data-sal-easing="ease-in-cubic"
          >
            {content.node.heading}
          </h2>
          {content.node.productItems.length > 0 && (
            <div className="products__slider">
              <div className="glider-contain">
                <div className="glider glider-productCarousel">
                  {content.node.productItems.map((product) => (
                    <Product product={product} key={product.id} />
                  ))}
                </div>
                <button type="button" className="glider-prev glider-productCarousel-prev material-icons-outlined">
                  keyboard_arrow_left
                </button>
                <button type="button" className="glider-next glider-productCarousel-next material-icons-outlined">
                  keyboard_arrow_right
                </button>
                <div role="tablist" className="glider-productCarousel__dots" />
              </div>
            </div>
          )}
        </div>
      </div>

      <div className="divider bg-blue_light"> </div>
    </section>
  );
};

Products.propTypes = {
  contentModuleId: PropTypes.string.isRequired,
};

export default Products;
