import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useStaticQuery, graphql } from 'gatsby';
import ReactPlayer from 'react-player';

const SingleVideo = ({ contentModuleId }) => {
  const data = useStaticQuery(graphql`
    query {
      allContentfulSectionSingleVideo {
        edges {
          node {
            id
            videoUrl
            name
            divider
          }
        }
      }
    }
  `);

  const content = data.allContentfulSectionSingleVideo.edges.find(
    edge => edge.node.id === contentModuleId
  );
  const {
    videoUrl,
    name,
    divider
  } = content.node;

  return (
    <section id="singleVideo" className="singleVideo">
      <div className="container">
        <div className="inner">
           <div className='player-wrapper'>
            <ReactPlayer
              className='react-player'
              width='100%'
              height='100%'
              url={videoUrl}
            />
          </div>
        </div>
      </div>

      { divider && (
        <div className="divider bg-blue_light"> </div>
      )}
    </section>
  );
};

SingleVideo.propTypes = {
  contentModuleId: PropTypes.string.isRequired,
};

export default SingleVideo;
