import React, { useEffect, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import AtolliLink from '../utils/atolliLink';
import { pagePathOrUrl } from '../utils/pagePath';

const PlasticMaterialDropdownItem = ({ plasticMaterialItem, nodeLocale }) => {
  const [isOpen, setIsOpen] = useState(false);

  const handleClick = useCallback(() => {
    setIsOpen((value) => !value);
  }, [setIsOpen]);

  useEffect(() => {
    const menu = document.getElementById(plasticMaterialItem.name);

    if (isOpen) {
      if (menu.classList.contains('hidden')) {
        menu.classList.remove('hidden');
      }
    } else {
      menu.classList.add('hidden');
    }
  }, [isOpen]);

  return (
    <ul>
      <li>
        <button key={plasticMaterialItem.id} id={plasticMaterialItem.name + plasticMaterialItem.id} data-dropdown-toggle={plasticMaterialItem.name} type="button" onClick={handleClick}>
          {plasticMaterialItem.name}
          {
            isOpen ? <span className="arrow">&#9650;</span> : <span className="arrow">&#9660;</span>
          }

        </button>
        {plasticMaterialItem.menuItems !== null && plasticMaterialItem.menuItems !== undefined && (

        <div id={plasticMaterialItem.name} className="hidden">

          <ul>
            {plasticMaterialItem.menuItems.map((menuItem) => (

              <li key={menuItem.id}>

                <AtolliLink to={pagePathOrUrl(nodeLocale, menuItem.url)}>
                  {menuItem.title}
                </AtolliLink>

              </li>
            ))}
          </ul>

        </div>
        )}

      </li>
    </ul>

  );
};

PlasticMaterialDropdownItem.propTypes = {
  plasticMaterialItem: PropTypes.object.isRequired,
  nodeLocale: PropTypes.string.isRequired,
};

export default PlasticMaterialDropdownItem;
