import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useStaticQuery, graphql } from 'gatsby';

import Glider from 'glider-js';
import Reference from '../components/reference';

const References = ({ contentModuleId }) => {
  const data = useStaticQuery(graphql`
    {
      allContentfulSectionReferences {
        edges {
          node {
            id
            heading
            itemsDisplayedAtOnce
            itemsToScrollAtOnce
            divider
            referenceItems {
              id
              name
              linkText
              linkUrl
              heading
              description
              image {
                fluid(maxWidth: 400, quality: 80) {
                  ...GatsbyContentfulFluid
                }
              }
            }
          }
        }
      }
    }
  `);

  const content = data.allContentfulSectionReferences.edges.find(
    (edge) => edge.node.id === contentModuleId
  );

  const {
    heading,
    description,
    referenceItems,
    itemsDisplayedAtOnce,
    itemsToScrollAtOnce,
    divider,
  } = content.node;


  // https://nickpiscitelli.github.io/Glider.js/
  const initSlider = () => {
    const glider = new Glider(document.querySelector('.glider-referenceCarousel'), {
      slidesToShow: 1,
      slidesToScroll: 1,
      itemWidth: 150,
      dots: '.glider-references__dots',
      draggable: true,
      arrows: {
        prev: '.glider-referencesCarousel-prev',
        next: '.glider-referencesCarousel-next',
      },
      responsive: [
        {
          // screens greater than
          breakpoint: 768,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1,
            itemWidth: 150,
            duration: 0.25,
          }
        },{
          // screens greater than
          breakpoint: 1180,
          settings: {
            slidesToShow: itemsDisplayedAtOnce,
            slidesToScroll: itemsToScrollAtOnce,
            itemWidth: 150,
            duration: 0.25,
          },
        },
      ],
    });
  };

  useEffect(() => {
    initSlider();
  });

  return (
    <section className="references">
      <div className="container">
        <div className="inner">
          <h2
          className="section__title w-full"
          data-sal="fade"
          data-sal-easing="ease-in-cubic"
          >
            {heading}
          </h2>

          {referenceItems.length > 0 && (
            <div className="references__slider">
              <div className="glider-contain">
                <div className="glider glider-referenceCarousel">
                  {referenceItems.map((reference) => (
                    <Reference reference={reference} key={reference.id} />
                  ))}
                </div>
                <button type="button" className="glider-prev glider-referencesCarousel-prev material-icons">
                  keyboard_arrow_left
                </button>
                <button type="button" className="glider-next glider-referencesCarousel-next material-icons">
                  keyboard_arrow_right
                </button>
                <div role="tablist" className="glider-references__dots" />
              </div>
            </div>
          )}
        </div>
      </div>

      { divider && (
        <div className="divider bg-blue_dark"> </div>
      )}

    </section>
  );
};

References.propTypes = {
  contentModuleId: PropTypes.string.isRequired,
};

export default References;
