import React from 'react';
import PropTypes from 'prop-types';
import Img from 'gatsby-image';
import AtolliLink from '../utils/atolliLink';

const HeroCarouselItem = ({ name, image, linkTo }) => (
  <div className="heroCarousel__item">
    <div
      className="heroCarousel__item-content"
      data-sal-duration="500"
      data-sal-delay="200"
      data-sal="zoom-in"
    >
      <AtolliLink to={linkTo}>
        <Img
          fluid={image.fluid}
          alt={name}
          className="heroCarousel__item-image"
        />
      </AtolliLink>
    </div>
  </div>
);

HeroCarouselItem.propTypes = {
  name: PropTypes.string.isRequired,
  linkTo: PropTypes.string.isRequired,
  image: PropTypes.object.isRequired,
};

export default HeroCarouselItem;
