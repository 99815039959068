import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import enFlag from 'assets/images/English.svg';
import fiFlag from 'assets/images/Finnish.svg';
import { StaticImage } from 'gatsby-plugin-image';
import AtolliLink from '../utils/atolliLink';
import { pagePath, pagePathOrUrl } from '../utils/pagePath';

const Header = ({ menus, nodeLocale, slug }) => {
  let navMenuButton;
  let navMenu;

  const mainMenu = menus !== null && menus !== undefined
    ? menus.find((menu) => menu.type === 'primary')
    : null;

  const initSmoothScroll = () => {
    if (typeof window !== 'undefined') {
      const SmoothScroll = require('smooth-scroll');

      const scroll = new SmoothScroll('a[href*="#"]', {
        speed: 800,
        speedAsDuration: true,
        easing: 'easeOutQuart',
      });
    }
  };

  const toggleNav = () => {
    navMenu.classList.toggle('nav__menu--active');

    navMenuButton.classList.toggle('nav__menu-button--active');

    document.body.classList.toggle('disable-scroll');
  };

  useEffect(() => {
    navMenuButton = document.getElementById('nav_menu_button');

    navMenu = document.getElementById('nav_menu');

    initSmoothScroll();
  });

  const langSelection = (locale, slug) => {
    const t = {
      'fi-FI': {
        title: 'English',
        href: `/en${slug}`, /*  href: `/en${slug}` */
        flag: enFlag,
      },
      'en-US': {
        title: 'Suomeksi',
        href: `/fi${slug}`, /* '/fi' */
        flag: fiFlag,
      },
    };

    return t[locale];
  };

  const closeMenu = () => {
    let navMenuButton;
    let navMenu;
    navMenuButton = document.getElementById('nav_menu_button');
    navMenu = document.getElementById('nav_menu');
    navMenu.classList.toggle('nav__menu--active');
    navMenuButton.classList.toggle('nav__menu-button--active');
    document.body.classList.toggle('disable-scroll');
    if (navMenuButton.classList.contains('nav__menu-button--active')) {
      navMenuButton.classList.remove('nav__menu-button--active');

      navMenu.classList.remove('nav__menu--active');

      document.body.classList.remove('disable-scroll');
    }
  };

  return (
    <header className="header">
      <div className="container">
        <div className="inner">
          <AtolliLink to={pagePath(nodeLocale, '/')} className="logo-link">
            <StaticImage
              critical
              src="../assets/images/atolli-logo.svg"
              alt="Atolli"
              className="logo-image-container"
              title="Atolli"
              placeholder="blurred"
              objectFit="contain"
            />
          </AtolliLink>
          {mainMenu !== null && mainMenu !== undefined && (
            <nav>
              <h2 className="hidden">Top navigation</h2>
              <ul
                id="nav_menu"
                className="nav__menu"
                data-sal-duration="500"
                data-sal-delay="100"
                data-sal="slide-up"
              >
                {mainMenu.menuItems.map((item) => (
                  <li key={item.id}>
                    <AtolliLink to={pagePathOrUrl(nodeLocale, item.url)} onClick={closeMenu}>
                      {item.title}
                    </AtolliLink>
                  </li>
                ))}

                <AtolliLink to={langSelection(nodeLocale, slug).href} className="mx-5 my-2" onClick={closeMenu}>
                  <img
                    src={langSelection(nodeLocale).flag}
                    alt={langSelection(nodeLocale).title}
                    className="flag"
                    data-sal-duration="1000"
                    data-sal-delay="300"
                    data-sal="slide-left"
                  />

                </AtolliLink>

              </ul>

              <button
                id="nav_menu_button"
                type="button"
                className="nav__menu-button"
                aria-label="mobile menu"
                onClick={toggleNav}
              >
                {/* <!-- These span elements form the X in mobile menu corner --> */}
                <span />
                <span />
                <span />
              </button>
            </nav>
          )}
        </div>
      </div>
    </header>
  );
};

Header.defaultProps = {
  menus: null,
};

Header.propTypes = {
  menus: PropTypes.any,
  nodeLocale: PropTypes.string.isRequired,
  slug: PropTypes.string.isRequired,
};

export default Header;
