import React from 'react';
import PropTypes from 'prop-types';
import Img from 'gatsby-image';

import AtolliLink from '../utils/atolliLink';

const ImageGalleryItem = ({ imageGalleryItem }) => (

  <AtolliLink className="imageGallery__item-link" to={imageGalleryItem.image.fluid.src} rel="nofollow">
    <Img
      fluid={imageGalleryItem.image.fluid}
      className="imageGallery__item-image"
      data-src={imageGalleryItem.image.fluid.src}
    />
    <div className="imageGallery__item-name">{imageGalleryItem.name}</div>
  </AtolliLink>

);

ImageGalleryItem.propTypes = {
  imageGalleryItem: PropTypes.object.isRequired,
};

export default ImageGalleryItem;
